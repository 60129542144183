import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

//firebase
import firebase from "@/utils/firebaseInit"
import {dbCollections} from "@/utils/firebaseCollection"
const db = firebase.firestore()
const months=["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'checkbox', sortable: false },
    { key: 'no', sortable: false },
    { key: 'university_name', sortable: false },
    { key: 'field_name', sortable: false },
    { key: 'course_name', sortable: false },
    { key: 'mode_of_application', sortable: false },
    { key: 'intake', sortable: false },
    { key: 'ielts/toefl_band_scores', sortable: false },
    // { key: 'email', sortable: false },
    // { key: 'role', sortable: false },
    // {
    //   key: 'currentPlan',
    //   label: 'Plan',
    //   formatter: title,
    //   sortable: true,
    // },
    // { key: 'status', sortable: true },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(null)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  //Select Filters
  const field = ref(null)
  const course = ref(null)
  const intake = ref(null)
  const bandScore = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  
  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, field,course,intake,bandScore], () => {
    refetchData()
  })
  
  var usersList = []
  const fetchUsers = (ctx, callback) => {
    var users = []
    console.log("get List")
    if(usersList.length==0)
    {
      db
        .collection(dbCollections.COURSES)
        .orderBy('createdAt')
        .get()
        .then(querySnapshot=>{
          if(!querySnapshot.empty)
          {
            var startIndex = (currentPage.value-1)*perPage.value+1
            var endIndex = startIndex+perPage.value-1
            var index = 1
            var dataIndex = 1
            console.log(startIndex,endIndex)
            querySnapshot.forEach(doc=>{

              //Turn isDeleted Flag = false
              // db
              //   .collection(dbCollections.COURSES)
              //   .doc(doc.id)
              //   .update({
              //     isDeleted:false
              //   })
              //   .catch(error => console.log(error))

              if(!doc.data().isDeleted)
              {
                usersList.push(doc.data())
              }
              // if(!doc.data().isDeleted && doc.data().role == 'employee')
              // {
              //   repOptions.push({value:{name:doc.data().name,eid:doc.data().employeeId,id:doc.data().id},text:doc.data().name})
              // }
              if(dataIndex>=startIndex && dataIndex<=endIndex && !doc.data().isDeleted)
              {
                users.push({
                  'id':doc.data().id,

                  'university_name':doc.data().universityData,
                  'field_name':doc.data().fieldData,
                  'course_name':doc.data().courseName,
                  'mode_of_application':doc.data().modeOfApplication,
                  'intake':doc.data().intake,
                  'ielts/toefl_band_scores':parseFloat(doc.data().bandScores),

                  'isDeleted':doc.data().isDeleted||false,
                })
                dataIndex++;
              }
              if(index==querySnapshot.size)
              {
                totalUsers.value = usersList.length
                callback(users)
              }
              index++;
            })
          }
          else
          {
            totalUsers.value = querySnapshot.size
            callback(users)
          }
        })
        .catch(error => console.log(error))
    }
    else
    {
      console.log("Already has list data")
      var startIndex = (currentPage.value-1)*perPage.value+1
      var endIndex = startIndex+perPage.value-1
      var index = 1
      // console.log(startIndex,endIndex,searchQuery.value)
      usersList.forEach(data=>{
        if(searchQuery.value=='' && !field.value && !course.value)
        {
          console.log("if")
          if(index>=startIndex && index<=endIndex && !data.isDeleted)
          {
            users.push({
              'id':data.id,

              'university_name':data.universityData,
              'field_name':data.fieldData,
              'course_name':data.courseName,
              'mode_of_application':data.modeOfApplication,
              'intake':data.intake,
              'ielts/toefl_band_scores':parseFloat(data.bandScores),

              'isDeleted':data.isDeleted||false,
            })
            // index++
          }
          if(index==usersList.length)
          {
            // if(currentPage.value > 1 && users.length==0)
            // {
            //   currentPage.value -= currentPage.value
            // }
            totalUsers.value = usersList.length
            callback(users)
          }
          index++;
        }
        else if(!data.isDeleted)
        {
          // if(searchQuery.value!='')
          // {
            console.log("else if",field.value.id , data.fieldData.id)
            var inqDate = `${new Date(data.createdAt.seconds*1000).getDate()} ${months[new Date(data.createdAt.seconds*1000).getMonth()]}, ${new Date(data.createdAt.seconds*1000).getFullYear()}`
            if(
              field.value.id && data.fieldData.id.toLowerCase().includes(field.value.id.toLowerCase()) ||
              course.value.id && data.id.toLowerCase().includes(course.value.id.toLowerCase())
            )
            {
              users.push({
                'id':data.id,
  
                'university_name':data.universityData,
                'field_name':data.fieldData,
                'course_name':data.courseName,
                'mode_of_application':data.modeOfApplication,
                'intake':data.intake,
                'ielts/toefl_band_scores':parseFloat(data.bandScores),

                'isDeleted':data.isDeleted||false,
              })
            }
            if(index==usersList.length)
            {
              // if(currentPage.value > 1 && users.length==0)
              // {
              //   currentPage.value -= currentPage.value
              // }
              totalUsers.value = users.length
              callback(users)
            }
            index++;
          // }
        }
      })
    }

    // for (let index = 0; index < 10; index++) {
    //   users.push({
    //     'No':index,
    //     'Date of Inquiry':`Date ${index}`,
    //     'Student Name':`Name ${index}`,
    //     'Phone no.':9462489464654,
    //     'College Name.':`Test`,
    //     'Branch.':`abcd`,
    //     'CGPA(%).':(index+1*(Math.random()*100)%100).toFixed(2),
    //     'actions':`<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-icon" > Create Account </b-button> <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-icon" > Create New Inquiry </b-button>`
    //   })
    // }
    // callback(users)
  }

  // const closeInquiry = (ctx, callback)=>{
  //   var index = (currentPage.value-1) * perPage.value + ctx.index + 1
  //     console.log("closeInquiry",index,ctx)
  //     db
  //       .collection(dbCollections.COURSES)
  //       .doc(ctx.item.id)
  //       .update({
  //         isDeleted:true
  //       })
  //       .then(()=>{
  //         usersList.splice(index-1,1)
  //         console.log(usersList)
  //         refetchData()
  //       })
  //       .catch(error=>{
  //         console.log(error)
  //       })
  // }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    // closeInquiry,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
    field,
    course,
    intake,
    bandScore,
  }
}
