<template>

  <div>

    <!-- <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      @refetch-data="refetchData"
    /> -->

    <!-- Filters -->
    <!-- <users-list-filters
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
    /> -->

    <!-- Filters -->
    <div class="card-header" style="padding:0px 0px 10px 0rem">
      Filters
    </div>
    <b-card no-body class="custom-card_padding">
      <b-row style="">
        <b-col md="3">
          <b-form-group label="Field">
            <b-form-select
                v-model="field"
                :options="fieldOptions"
                placeholder="Select"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Course">
            <b-form-select
                v-model="course"
                :options="courseOptions"
                placeholder="Select"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Intake">
            <b-form-select
                v-model="intake"
                :options="intakeOptions"
                placeholder="Select"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="IELTS/TOEFL Band Scores">
            <b-form-select
                v-model="bandScore"
                :options="bandScores"
                placeholder="Select"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >      
    <div class="mb-2">

        <!-- Table Top -->
        <b-row>

          <b-col
            cols
            md="6"
            class="d-flex align-items-center"
          >
            <div>
              <h4 class="black m-0" style="color:#505050"><b>Suggested Universities</b></h4>
            </div>
          </b-col>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <!-- <label>entries</label> -->
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative suggested-uni_listtable"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        hover
        empty-text="No matching records found"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Head: CheckBox. -->
        <template #head(checkbox)="">
          <div>
            <span><input type="checkbox"></span>
          </div>
        </template>

        <!-- Column: CheckBox. -->
        <template #cell(checkbox)="data">
          <div>
            <span>
              <input type="checkbox" @change="data.item.id">
            </span>
          </div>
        </template>

        <!-- Column: No. -->
        <template #cell(no)="data">
          <div>
            <span>{{((currentPage-1) * perPage + data.index + 1)>9?'':0}}{{(currentPage-1) * perPage + data.index + 1}}</span>
          </div>
        </template>

        <!-- Column: University Name. -->
        <template #cell(university_name)="data">
          <div>
            <span>{{data.item.university_name.name}}</span>
          </div>
        </template>

        <!-- Column: Field Name. -->
        <template #cell(field_name)="data">
          <div>
            <span>{{data.item.field_name.name}}</span>
          </div>
        </template>

        <!-- Column: Intake. -->
        <template #cell(intake)="data">
          <div>
            <span v-for="(data2,index) in data.item.intake" :key="index">{{index>0?'/':''}}{{data2}}</span>
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BModal,BFormSelect,BFormGroup,
  BFormCheckboxGroup
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useUsersList from './useUniversitiesList'

import firebase from '@/utils/firebaseInit'
import { dbCollections } from '@/utils/firebaseCollection'
const db = firebase.firestore()

import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BFormSelect,
    BFormGroup,
    BFormCheckboxGroup,
    vSelect,
  },
  data(){
    return{
      selectedUser:"",
      selected:[],
      fieldOptions:[{value:null,text:'Select'}],
      allCourse:[],
      courseOptions:[{value:null,text:'Select'}],
      intakeOptions:[{value:null,text:'Select'},{value:"WS",text:'WS'},{value:'SS',text:'SS'}],
      bandScores:[{value:null,text:'Select'}],
    }
  },
  created() {
    var self = this

    //Get Fields
    db
        .collection(dbCollections.FIELDS)
        .orderBy('createdAt')
        .get()
        .then((querySnapshot)=>{
          querySnapshot.forEach(doc=>{
            self.fieldOptions.push({text:doc.data().fieldName,value:{name:doc.data().fieldName,id:doc.data().id}})
          })
        })
        .catch(error=>{
            console.log(error)
        })

    //Get Course
    db
        .collection(dbCollections.COURSES)
        .orderBy('createdAt')
        .get()
        .then((querySnapshot)=>{
            querySnapshot.forEach(doc=>{
              self.courseOptions.push({text:doc.data().courseName,value:{name:doc.data().courseName,id:doc.data().id,fieldId:doc.data().fieldData.id}})
            })
        })
        .catch(error=>{
            console.log(error)
        })
  },

  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,

      field,
      course,
      intake,
      bandScore,
    } = useUsersList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      field,
      course,
      intake,
      bandScore,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },
  methods:{
    handleRowClick(data)
    {
      // console.log(data)
      //Show User Details
      this.$router.push({name:'student-information',params:{name:data.student_name,id:data.id}})
    },
  }
}
</script>
<style>
.position-relative.table-responsive thead tr th{
  white-space: nowrap !important;
}
/* .position-relative.table-responsive tbody tr td{
  white-space: nowrap !important;
} */

.s_modal_title{
    width: 100%;
    
    font-weight: 600;
    font-size: 20px;
}
</style>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.position-relative.suggested-uni_listtable {
    padding: 0px!important;
}
</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
