<template>
    <div>
        <b-spinner variant="primary" v-if="isSpinner" large class="pageLoadSpinners"/>
        <div v-show="!isSpinner">
            <!-- Personal Information -->
            <b-card>
                <b-tabs class="studentinformation_tabs_wrapper" v-if="userData">
                    <!-- Profile -->
                    <b-tab title="Profile">
                        <b-card>
                            <b-row>
                                <!-- Profile Information -->
                                <b-col md="2">
                                    <img :src="userData.imageUrl" v-if='userData.imageUrl !== ""' alt="profile image" width="160px" height="145px" class="profile_img">
                                    <img v-else src="@/assets/images/avatars/defaultUser.jpg" alt="profile image" width="160px" height="145px" class="profile_img">
                                </b-col>
                                <b-col>
                                    <b-row>
                                        <b-col md="4" lg="3">
                                            <b-form-group label="User ID">
                                                {{userData.uniqueId}}
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="4" lg="3">
                                            <b-form-group label="Name">
                                                {{userData.name}}
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="4" lg="3">
                                            <b-form-group label="Email">
                                                {{userData.email}}
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="4" lg="3">
                                            <b-form-group label="Mobile No.">
                                                {{`${userData.countryPhoneCode ? userData.countryPhoneCode.dialCode : '+91'} ` + ( (userData.countryPhoneCode ? formatPhoneNumber(userData.mobileNumber,userData.countryPhoneCode.mask) : formatPhoneNumber(userData.mobileNumber,'99999 99999')))}}
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="4" lg="3">
                                            <b-form-group label="Date of Birth" v-if="userData">
                                                {{userData.dob.split("-")[2]}} {{months[userData.dob.split("-")[1]-1]}}, {{userData.dob.split("-")[0]}}
                                                    <!-- {{userData.displayDob}} -->
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="4" lg="3">
                                            <b-form-group label="Gender">
                                                {{userData.gender}}
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="4" lg="3">
                                            <b-form-group label="Communication Address" v-if="userData">
                                                {{`${userData.addressLine1}, ${userData.addressLine2!=''?userData.addressLine2+', ':''}${userData.city.name}, ${userData.state.name} - ${userData.pincode}`}}
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="4" lg="3">
                                            <b-form-group label="Representative" v-if="userData">
                                                {{userData.representative.name||'--'}}
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="4" lg="3">
                                            <b-form-group label="Representative Mobile No." v-if="userData">
                                                {{userData.representative.phoneNo?userData.representative.phoneNo:'--'}}
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="4" lg="3">
                                            <b-form-group label="Document Module">
                                                <span v-for="(data,index) in userData.documentModule" :key="index">
                                                    {{data.title}}{{index!=userData.documentModule.length-1?', ':'' }}
                                                </span>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="4" lg="3" v-if="userData.status">
                                            <b-form-group label="Status">
                                                <b-dropdown
                                                    id="status-dropdown-1"
                                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                                    :text="userData.status.status"
                                                    v-if="$route.meta.action=='write' || $route.meta.action==undefined"
                                                    :variant="userData.status.variant"
                                                >
                                                    <b-dropdown-item variant="warning" @click.prevent="changeStatus({status:'Application Pending',variant:'warning'})">Application Pending</b-dropdown-item>
                                                    <b-dropdown-item variant="primary" @click.prevent="changeStatus({status:'Application Inprogress',variant:'primary'})">Application Inprogress</b-dropdown-item>
                                                    <b-dropdown-item variant="info" @click.prevent="changeStatus({status:'Application Submitted',variant:'info'})">Application Submitted</b-dropdown-item>
                                                    <b-dropdown-item variant="success" @click.prevent="changeStatus({status:'Admission Successful',variant:'success'})">Admission Successful</b-dropdown-item>
                                                    <b-dropdown-item variant="danger" @click.prevent="changeStatus({status:'Application Process Hold',variant:'danger'})">Application Process Hold</b-dropdown-item>
                                                </b-dropdown>
                                                <b-badge
                                                    pill
                                                    v-else
                                                    :variant="'light-'+userData.status.variant"
                                                >
                                                    {{userData.status.status}}
                                                </b-badge>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="4" lg="3">
                                            <b-form-group label="Intake" v-if="userData">
                                                {{userData.intake||'--'}}
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="4" lg="3">
                                            <b-form-group label="Year" v-if="userData">
                                                {{userData.year||'--'}}
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                
                            </b-row>
                        
                        </b-card>
                    
                        <!-- Educational Details -->
                        <b-card
                            header="Education Details"
                            header-bg-variant="#f4f4f4"
                            style="background-color:#f2f2f2"
                            v-if="userData.educationDetails"
                        >
                            <b-row  class="card-title-border-bottom" v-for="(data,index) in userData.educationDetails" :key="index">
                                <b-col md="3" v-if="data.education && data.edId == undefined">
                                    <b-form-group label="Education">
                                        {{data.education}}
                                    </b-form-group>
                                </b-col>
                                <b-col md="3" v-if="data.education && data.edId">
                                    <b-form-group label="Degree Name">
                                        {{data.education}}
                                    </b-form-group>
                                </b-col>
                                <b-col md="3" v-if="data.education && data.bsfId">
                                    <b-form-group label="Branch/Stream/Field Name">
                                        {{data.branchOrStreamOrFieldName}}
                                    </b-form-group>
                                </b-col>
                                <b-col md="3">
                                    <b-form-group label="School/College Name">
                                        {{data.schoolOrCollegeName}}
                                    </b-form-group>
                                </b-col>
                                <b-col md="3">
                                    <b-form-group label="Board/University Name">
                                        {{data.boardUniversityName}}
                                    </b-form-group>
                                </b-col>
                                <b-col md="3">
                                    <b-form-group :label="data.cgpa_percentage">
                                        {{data.cgpa_percentageValue}}
                                    </b-form-group>
                                </b-col>
                                <b-col md="3">
                                    <b-form-group label="Year of Passing">
                                        {{data.yearOfPassing}}
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-card>
                        <b-row class="match-height">
                            <b-col>
                                <b-row>
                                    <!-- IELTS/TOEFL -->
                                    <b-col md="12">
                                        <b-card
                                        header="Language"
                                        header-bg-variant="#f2f2f2"
                                        style="background-color:#f2f2f2"
                                        >
                                            <!-- <b-row md="6" class="card-title-border-bottom">
                                                <b-col md="12">
                                                    <b-form-group label="Language">
                                                        {{userData.language}}
                                                    </b-form-group>
                                                </b-col>
                                            </b-row> -->
                                            <b-row md="6" class="card-title-border-bottom">
                                                <b-col md="12">
                                                    <b-form-group label="German Language">
                                                        {{userData.germanLanguage}}
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                        </b-card>
                                    </b-col>
                                    <!-- German Language -->
                                    <b-col md="12">
                                        <b-card
                                            header="Exams"
                                            header-bg-variant="#f2f2f2"
                                            style="background-color:#f2f2f2"
                                            v-if="userData.exams"
                                        >
                                            <b-row class="card-title-border-bottom" v-show="userData.exams.length > 0">
                                                <b-col md="6" v-for="(data,index) in userData.exams" :key="index">
                                                    <b-form-group :label="data.title">
                                                        {{parseFloat(data.score).toFixed(1)}}
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                            <b-row class="card-title-border-bottom" v-show="userData.exams.length <= 0">
                                                <b-col md="6">
                                                    <b-form-group>
                                                        None
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                        </b-card>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <!-- Field of Interest -->
                            <b-col md="6">
                                <b-row>
                                    <b-col md="12">
                                        <b-card
                                            header="Student Interest"
                                            header-bg-variant="#f2f2f2"
                                            style="background-color:#f2f2f2"
                                            v-if="userData.exams"
                                        >
                                            <b-row md="6" class="card-title-border-bottom">
                                                <b-col md="12">
                                                    <b-form-group label="Area of Interest">
                                                        <span class="output_span">{{userData.areaOfInterest||'--'}}</span>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                        </b-card>
                                    </b-col>
                                    <b-col md="12">
                                        <b-card
                                            header="Admission offered"
                                            header-bg-variant="#f2f2f2"
                                            style="background-color:#f2f2f2"
                                            v-if="offeredApplications"
                                        >
                                            <b-row md="6" class="card-title-border-bottom">
                                                <b-col md="6">
                                                    <b-form-group label="College" style="margin-bottom:0px !important"></b-form-group>
                                                </b-col>
                                                <b-col md="6">
                                                    <b-form-group label="Course"  style="margin-bottom:0px !important"></b-form-group>
                                                </b-col>
                                            </b-row>
                                            <div v-if="offeredApplications.length">
                                                <b-row md="6" v-for="(data,index) in offeredApplications" :key="index">
                                                    <b-col md="6">
                                                        <label style="font-size: 16px;" :style="data.isBold ? 'font-weight: 600;' : ''">{{data.college_name}}</label>
                                                    </b-col>
                                                    <b-col md="6">
                                                        <label style="font-size: 16px;" :style="data.isBold ? 'font-weight: 600;' : ''">{{data.dispCourseName}}</label>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                            <div v-else>
                                                <b-row md="6">
                                                    <b-col md="6">
                                                        <label style="font-size: 16px;">--</label>
                                                    </b-col>
                                                    <b-col md="6">
                                                        <label style="font-size: 16px;">--</label>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                        </b-card>
                                    </b-col>
                                    <b-col md="12" v-if="false">
                                        <b-card
                                            header="Field of Interest"
                                            header-bg-variant="#f2f2f2"
                                            style="background-color:#f2f2f2"
                                        >
                                            <b-row md="6"  class="card-title-border-bottom">
                                                <b-col v-for="(data,index) in userData.fieldOfInterests" :key="index" :class="{'bottom-border':index!=userData.fieldOfInterests.length-1}" md="12">
                                                    <b-form-group :label="data.field.name">
                                                        {{data.course.name}}
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                        </b-card>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <!-- Payment -->
                            <b-col md="6" v-if="false">
                                <b-card
                                    style="background-color:#f2f2f2"
                                >
                                    <div class="card-header" style="padding:0px 0px 1rem 0px;">
                                        Payment
                                        <b-button
                                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                            class="btn-icon"
                                            style="background-color:#0d4ea0!important"
                                            v-b-modal.modal-add-payment
                                        >
                                            Add Payment
                                        </b-button>
                                    </div>
                                    <div>
                                        <b-row
                                        md="6"
                                        class="card-title-border-bottom"
                                        v-for="(data,index) in userData.payments" :key="index"
                                        >
                                            <b-col md="4">
                                                <b-form-group label="Payment Received Date">
                                                    {{data.paymentReceivedDate.split("-")[2]}} {{months[data.paymentReceivedDate.split("-")[1]-1]}} {{data.paymentReceivedDate.split("-")[0]}}
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="4">
                                                <b-form-group label="Mode Of Payment">
                                                    {{data.modeOfPayment}}
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="4" v-if="data.modeOfPayment=='Cheque'">
                                                <b-form-group label="Bank Name">
                                                    {{data.bankName}}
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="4" v-if="data.modeOfPayment=='Cheque'">
                                                <b-form-group label="Cheque No.">
                                                    {{data.chequeNo}}
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="4">
                                                <b-form-group label="Rupees (INR)">
                                                    {{parseFloat(data.rupees).toFixed(2)}}
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="4">
                                                <b-form-group label="Remark" v-if="data.modeOfPayment == 'Other'">
                                                    {{data.remark}}
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </div>
                                </b-card>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <!-- Documents -->
                    <b-tab title="Documents">
                        <DocumentManagement :userData="userData"/>
                    </b-tab>
                    <!-- Suggested University -->
                    <!-- <b-tab title="Suggested University"> -->
                        <!-- Suggested Universities -->
                        <!-- <SuggestedUniversities/> -->
                    <!-- </b-tab> -->
                    <!-- APPLICATIONS -->
                    <b-tab title="Applications">
                        <!-- Suggested Universities -->
                        <b-row class="mb-1">
                        <!-- <b-col  class="d-flex align-items-center justify-content-end mb-1 mb-md-0"> -->
                            <!-- <div class="d-flex status_top_line">
                                <ul class="d-flex align-items-center">
                                <li><span class="panding-bg round_small_status"></span><span>Pending</span></li>
                                <li><span class="inprogress-bg round_small_status"></span><span>Inprogress</span></li>
                                    <li><span class="ad_success round_small_status"></span><span>Application Successful</span></li>
                                    <li><span class="ad_rejected round_small_status"></span><span>Application Rejected</span></li>
                                </ul>
                            </div> -->
                        <!-- </b-col> -->
                        <b-col cols="12" md="12" class="d-flex align-items-center justify-content-end mt-1 mb-md-0">
                            <div class="d-flex status_top_line">
                                <ul class="d-flex align-items-center">
                                <li><span class="panding-bg round_small_status"></span><span>Pending</span></li>
                                <li><span class="noteligible-bg round_small_status"></span><span>Not Eligible</span></li>
                                <li><span class="inprogress-bg round_small_status"></span><span>In progress</span></li>
                                <li><span class="paymentPending-bg round_small_status"></span><span>Payment Pending</span></li>
                                <li><span class="paymentSuccessfull-bg round_small_status"></span><span>Payment Successful</span></li>
                                <li><span class="applicationSubmitted-bg round_small_status"></span><span>Application Submitted</span></li>
                                    <li><span class="ad_success round_small_status"></span><span>Admission Offered</span></li>
                                    <li><span class="ad_rejected round_small_status"></span><span>Rejection</span></li>
                                </ul>
                            </div>
                        </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-table
                                :items="applicationData"
                                :fields="applicationColumn"
                                v-model="docCategoryTable"
                                :current-page="1"
                                striped
                                hover
                                responsive
                                show-empty
                                empty-text="No matching records found"
                                class="position-relative application_stdnt-mgmt"
                                >
                                <template #cell(intake)='data'>
                                    <span v-for='(datas,index) in data.item.intake' :key="index">
                                        {{datas}}
                                        <span v-if='index !== Number(data.item.intake.length - 1)'>,</span>
                                    </span>
                                </template>
                                <template #cell(applicationNumber)='data'>
                                    <div class="add_edu_link">
                                        <a @click.prevent="handleRowClick(data.item)">{{data.item.applicationNumber}}</a>
                                    </div>
                                </template>
                                <template #cell(selectedCollege)='data'>
                                    {{data.item.selectedCollege.length}}
                                </template>
                                <template #cell(shortListedColleges)='data'>
                                    <span v-if='data.item.shortListedColleges !== undefined'> {{data.item.shortListedColleges.length}} </span>
                                    <span v-else>0</span>
                                </template>
                                <template #cell(application_Status)='data'>
                                    <b-badge
                                        pill
                                        :class="[{'pendingCSSSClass':data.item.displayStatus === 'Payment Pending','applicationSubmitted' : data.item.displayStatus === 'Application Submitted','applicationNotEligibleStudent' : data.item.displayStatus === 'Not Eligible'}]"
                                        :variant="'light-'+data.item.applicationStatusVariant"
                                    >
                                        {{data.item.displayStatus}}
                                    </b-badge>
                                    <!-- <span 
                                    :class="{
                                        'badge badge-primary': (data.item.application_Status.toLowerCase()=='pending'), 
                                        'badge badge-success': (data.item.application_Status.toLowerCase()=='admissionsuccessful'), 
                                        'badge badge-warning':(data.item.application_Status.toLowerCase()=='inprogress'),
                                        'badge badge-danger':(data.item.application_Status.toLowerCase()=='admissionrejected')}"
                                    >
                                    {{data.item.displayStatus}}
                                    </span>                                 -->
                                </template>
                                <template #cell(id)='data'>
                                <!-- {{data.item.id}} -->
                                <div>
                                        <div class="d-flex status_top_line">
                                            <ul class="d-flex align-items-center">
                                                <li><span class="panding-bg round_small_status"></span><span>{{data.item.shortListStatus.shortlistPeding}}</span></li>
                                                <li><span class="noteligible-bg round_small_status"></span><span>{{data.item.shortListStatus.shortlistNotEligible}}</span></li>
                                                <li><span class="inprogress-bg round_small_status"></span><span>{{data.item.shortListStatus.shortlistinProgress}}</span></li>
                                                <li><span class="paymentPending-bg round_small_status"></span><span>{{data.item.shortListStatus.shortlist_pay_pend}}</span></li>
                                                <li><span class="paymentSuccessfull-bg round_small_status"></span><span>{{data.item.shortListStatus.shortPaySuccessful}}</span></li>
                                                <li><span class="applicationSubmitted-bg round_small_status"></span><span>{{data.item.shortListStatus.shortApplicationSub}}</span></li>
                                                <li><span class="ad_success round_small_status"></span><span>{{data.item.shortListStatus.shortlistSuccessfull}}</span></li>
                                                <li><span class="ad_rejected round_small_status"></span><span>{{data.item.shortListStatus.shortlistreject}}</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </template>
                                </b-table>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col
                                cols="12"
                                sm="6"
                                class="d-flex align-items-center justify-content-center justify-content-sm-start"
                            >
                                    <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ totalData }} entries</span>
                            </b-col>
                             <b-col cols="12" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalData"
                                    :per-page="perPage"
                                    first-number
                                    last-number
                                    class="mb-0 mt-1 mt-sm-0"
                                    @change="handlePageChange"
                                    prev-class="prev-item"
                                    next-class="next-item"/>
                             </b-col>
                        </b-row>
                    </b-tab>


                    <b-tab title="Visa">
                        <VisaDocuments :studentId="docId" :studentData="userData"/>
                    </b-tab>
                    <b-tab title="SOP">
                        <sopDocument/>
                    </b-tab>
                    <!-- Shortlist Universities -->
                    <!-- <b-tab title="Shortlist Universities">
                        <b-card-text>
                            Candy canes donut chupa chups candy canes lemon drops oat cake wafer. Cotton candy candy canes marzipan carrot cake. Sesame snaps lemon drops candy marzipan donut brownie tootsie roll. Icing croissant bonbon biscuit gummi bears. Pudding candy canes sugar plum cookie chocolate cake powder croissant.
                        </b-card-text>
                        <b-card-text>
                            Carrot cake tiramisu danish candy cake muffin croissant tart dessert. Tiramisu caramels candy canes chocolate cake sweet roll liquorice icing cupcake. Candy cookie sweet roll bear claw sweet roll.
                        </b-card-text>
                    </b-tab> -->

                </b-tabs>
            </b-card>

            <!--Add Payment -->
            <b-modal
                id="modal-add-payment"
                cancel-variant="outline-secondary"
                ok-title="Save"
                cancel-title="Cancel"
                centered
                title="Add Payment"
                :no-close-on-backdrop="true"
                @hidden="cancelPayment"
                @ok="(e)=>{e.preventDefault(); addPayment(e)}"
            >
            <div>
                <validation-observer ref="paymentValidator">
                <!-- form -->
                <b-form @submit.stop.prevent="addPayment">
                    <b-row v-if="addPaymentData">
                        <!-- Date -->
                        <b-col md="6">
                            <b-form-group label="Payment Received Date">
                            <validation-provider
                                #default="{ errors }"
                                name="payment received date"
                                rules="required"
                            >
                                <b-form-input
                                    id="example-input"
                                    v-model="addPaymentData.paymentReceivedDate"
                                    onkeydown="return false"
                                    type="date"
                                    placeholder="YYYY-MM-DD"
                                    autocomplete="off"
                                    show-decade-nav
                                    :state="errors.length > 0 ? false:null"
                                    :max="getMaxDate()"
                                     class="input-date"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            </b-form-group>
                        </b-col>

                        <!-- Mode Of Payment -->
                        <b-col md="6">
                            <b-form-group label="Mode Of Payment">
                            <validation-provider
                                #default="{ errors }"
                                name="mode of payment"
                                rules="required"
                            >
                            <div style="display: flex;justify-content: space-between;align-items:center;flex-wrap: wrap;height: 38px;">
                            <!-- <div> -->
                                <b-form-radio
                                    v-model="addPaymentData.modeOfPayment"
                                    plain
                                    name="mode of payment"
                                    value="Cash"
                                >
                                    Cash
                                </b-form-radio>
                                <b-form-radio
                                    v-model="addPaymentData.modeOfPayment"
                                    plain
                                    name="mode of payment"
                                    value="Cheque"
                                >
                                    Cheque
                                </b-form-radio>
                                <b-form-radio
                                    v-model="addPaymentData.modeOfPayment"
                                    plain
                                    name="mode of payment"
                                    value="Other"
                                >
                                    Other
                                </b-form-radio>
                            </div>
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            </b-form-group>
                        </b-col>

                        <!-- Bank Name -->
                        <b-col md="6" v-if="addPaymentData.modeOfPayment == 'Cheque'">
                            <b-form-group label="Bank Name">
                            <validation-provider
                                #default="{ errors }"
                                name="bank name"
                                rules="required|alpha_spaces"
                            >
                                <b-form-input
                                    v-model="addPaymentData.bankName"
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="Enter Text"
                                    @blur="addPaymentData.bankName=addPaymentData.bankName.trim()"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            </b-form-group>
                        </b-col>

                        <!-- Cheque No. -->
                        <b-col md="6"  v-if="addPaymentData.modeOfPayment == 'Cheque'">
                            <b-form-group label="Cheque No.">
                            <validation-provider
                                #default="{ errors }"
                                name="cheque no."
                                rules="required|integer|min:6|max:6"
                            >
                                <b-form-input
                                    v-model="addPaymentData.chequeNo"
                                    name="cheque no."
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="Enter number"
                                    @blur="addPaymentData.chequeNo=addPaymentData.chequeNo.trim()"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            </b-form-group>
                        </b-col>

                        <!-- Rupees (INR) -->
                        <b-col md="6">
                            <b-form-group label="Rupees (INR)">
                            <validation-provider
                                #default="{ errors }"
                                name="rupees"
                                rules="required|min_value:1"
                            >
                                <b-form-input
                                    v-model="addPaymentData.rupees"
                                    name="rupees"
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="0.00"
                                    @blur="addPaymentData.rupees=addPaymentData.rupees.trim()"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            </b-form-group>
                        </b-col>

                        <!-- Remark -->
                        <b-col md="12">
                            <b-form-group label="Remark" v-if="addPaymentData.modeOfPayment=='Other'">
                            <validation-provider
                                #default="{ errors }"
                                name="remark"
                                rules=""
                            >
                                <b-form-textarea
                                    v-model="addPaymentData.remark"
                                    name="remark"
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="Enter Text"
                                    @blur="addPaymentData.remark=addPaymentData.remark.trim()"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
                </validation-observer>
            </div>
            </b-modal>
        </div>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,BFormInput,BFormGroup,BForm,BRow,BCol,BButton,BTabs,BTab,BDropdown, BDropdownItem,
  BCardText,BFormSelect,BFormDatepicker,BFormRadio,BFormFile,BBadge,BFormTextarea, BTable,BSpinner,BPagination
} from 'bootstrap-vue'
import firebase from "@/utils/firebaseInit.js";
import firebaseTimeStamp from "firebase";
import { extend } from 'vee-validate';
import { required, email,max,max_value,integer,min_value,numeric,min,alpha_spaces } from 'vee-validate/dist/rules';
import * as updateQuery from '@/utils/FirebaseQueries/updateQueries/updateQueries.js'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
extend('required',required)
extend('max',max)
extend('min',min)
extend('min_value',min_value)
extend('email',email)
extend('alpha_spaces',alpha_spaces)
extend('max_value',max_value)
extend('integer',integer)
extend('numeric',numeric)

import { dbCollections } from "@/utils/firebaseCollection.js";
import { uploadImageFile } from '@/utils/FirebaseQueries/storageQuery/storageQueries'
// import firebase from "firebase";
const db = firebase.firestore()
import moment from 'moment'
//Import Sub Components
import DocumentManagement from "./documentManagement.vue";
import SuggestedUniversities from "./suggestedUniversities.vue";
import * as getQuery from '@/utils/FirebaseQueries/getQueries/getQueries.js'

import VisaDocuments from '@/views/admin/studentManagement/visaDocuments.vue';
import sopDocument from '@/views/admin/studentManagement/sopDocument.vue';
import { getCollectionDataTypesense } from '@/utils/Typesense/queries';
import formatPhoneNumber from '@/utils/phoneNumberFormat.js';

export default {
    directives: {
        Ripple
    },
    components: {
        ValidationProvider,
        ValidationObserver,
        BCard,
        BCardText,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
        BFormSelect,
        BFormDatepicker,
        BFormRadio,
        BFormFile,
        BTabs,
        BTab,
        BBadge,
        BFormTextarea,
        BDropdown,
        BDropdownItem,
        BTable,
        DocumentManagement,
        SuggestedUniversities,
        BSpinner,
        VisaDocuments,
        sopDocument,
        BPagination
    },   
    data() {
        return {
            isSpinner:false,
            docId:this.$route.params.id,
            userData:null,
            userName:"",
            months:["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"],
            addPaymentData:{
                paymentReceivedDate:'',
                modeOfPayment: '',
                bankName: '',
                chequeNo:'',
                remark:'',
                rupees: ''
            },
            applicationData:[],
            applicationColumn:[
                {key:'applicationNumber', label:'Application No.'},
                {key:'intake', label:'Intake'},
                {key:'selectedCollege', label:'No. of Suggested Course'},
                {key:'shortListedColleges', label:'No. of Shortlist Course'},
                {key:'ApplyDate', label:'Application Date'},
                {key:'application_Status', label:'Application Status'},
                {key:'id', label:''},
            ],
            docCategoryTable:null,
            perPage:10,
            currentPage:1,
            totalRows:0,
        }
    },
    watch:{
        $route: function () {
            this.getData();    
            self.fetchApplicationDataTypesense();      
        }
    },
    created() {
        var self = this

        if(!self.$route.params.id)
        {
            self.$router.push({name:'student-mgmt'})
        }
        self.getData();
        self.fetchApplicationDataTypesense();
        var currentUsers = JSON.parse(localStorage.getItem('currentUser'));

        // BLOCK UNBLOCK USER
        self.$root.$on('blockUser',()=>{    
            let updateObj = null;      
            // this.getData();
            if(self.userData !== null && self.userData !== undefined){
            let element = self.userData
            if(Object.keys(element).length > 0){
                self.$axios.post(dbCollections.APIURL+'disableUser',{uid:self.docId})
                .then((resp)=>{
                    if(resp.data.status==200)
                    {
                        if(element.isActive !== undefined){
                            updateObj = {
                                'isActive': !element.isActive
                            }
                        }else{
                            updateObj = {                           
                                'isActive': false
                            }                         
                        }
                        if(updateObj.isActive !== undefined){  
                            this.userUpdateActive(updateObj,callBack=>{
                                if(callBack == true){
                                    let notificationObj = {
                                        title: `Student Inactive`,
                                        type: 'studentInactive',
                                        userId: self.docId,
                                        message : `Your account has been inactivated by the ${currentUsers.fullName}`,
                                        currentUser:firebase.auth().currentUser.uid
                                    }
                                    this.$axios.post(dbCollections.APIURL+'api/v1/uploadDocument',notificationObj).then(()=>{
                                    }).catch((error)=>{
                                        console.error(error);
                                    })
                                    this.$root.$emit('stopSpinner')
                                    this.$toast({
                                    component: ToastificationContent,
                                    position: 'top-right',
                                    props: {
                                        title: 'User Inactive Successfully',
                                        icon: 'CoffeeIcon',
                                        variant: 'success',                               
                                    },
                                    })
                                    self.$root.$emit('editUserData',false)
                                    self.userData.isActive = false
                                    return
                                }else{
                                    this.$root.$emit('stopSpinner')
                                    return
                                }
                            }) 
                        }else{
                            this.$root.$emit('stopSpinner')
                            return
                        }
                    }
                    else
                    {
                        self.$root.$emit('stopSpinner')
                        self.$root.$emit('showToastMessage',resp.data.msg,'danger')
                    }
                })
                .catch(error=>{
                    self.$root.$emit('stopSpinner')
                    self.$root.$emit('showToastMessage',error.message,'danger')
                })
            }else{
                // self.getData();
                this.$root.$emit('stopSpinner')
            }
            }else{
                // self.getData();
                this.$root.$emit('stopSpinner')
            }   
        })
        // UNBLOCK USER
        self.$root.$on('unBlockUser',()=>{
            let updateObj = null;      
            // this.getData();
            if(self.userData !== null && self.userData !== undefined){           
            let element = self.userData;           
            if(Object.keys(element).length > 0){
                self.$axios.post(dbCollections.APIURL+'enableUser',{uid:self.docId})
                .then((resp)=>{
                    if(resp.data.status==200)
                    {
                        if(element.isActive !== undefined){
                            updateObj = {
                                'isActive': !element.isActive
                            }
                        }else{
                            updateObj = {
                                'isActive': false
                            }                         
                        }
                        if(updateObj.isActive !== undefined){     
                            this.userUpdateActive(updateObj,callBack=>{
                                if(callBack == true){        
                                    let notificationObj = {
                                        title: `Student Active`,
                                        type: 'studentActive',
                                        userId: self.docId,
                                        message : `Your account has been activated by the ${currentUsers.fullName}`,
                                        currentUser:firebase.auth().currentUser.uid
                                    }
                                    this.$axios.post(dbCollections.APIURL+'api/v1/uploadDocument',notificationObj).then(()=>{
                                    }).catch((error)=>{
                                        console.error(error);
                                    })           
                                    this.$toast({
                                    component: ToastificationContent,
                                    position: 'top-right',
                                    props: {
                                        title: 'User active Successfully',
                                        icon: 'CoffeeIcon',
                                        variant: 'success',                               
                                    },
                                    })
                                    self.userData.isActive = true
                                    self.$root.$emit('editUserData',true)
                                    this.$root.$emit('stopSpinner')
                                    return
                                }else{
                                    this.$root.$emit('stopSpinner')
                                }
                            }) 
                        }else{
                            this.$root.$emit('stopSpinner')
                        }
                    }
                    else
                    {
                        self.$root.$emit('stopSpinner')
                        self.$root.$emit('showToastMessage',resp.data.msg,'danger')
                    }
                })
                .catch(error=>{
                    self.$root.$emit('stopSpinner')
                    self.$root.$emit('showToastMessage',error.message,'danger')
                })
            }else{
                this.$root.$emit('stopSpinner')
            }
            }else{
                this.$root.$emit('stopSpinner')
            }
                 
        })
        // CREATE APPLICATION
        self.$root.$on('createApplicationStidentMgmt',()=>{
            self.$router.push({'name':'create-new-application'})
        })
    },
    methods: {
        formatPhoneNumber,
        handlePageChange(value) {
            this.currentPage = value;
            this.fetchApplicationDataTypesense(false)
        },
        handleRowClick(data)
        {           
            this.$router.push({name:'application-mgmt-Info',params: { id: data.id,name:this.userName }})
        },
        fetchApplicationDataTypesense(resetResult = true){
            try {
                var self = this;
                if (resetResult) {
                    self.currentPage = 1;
                    self.totalData = 0;
                }

                let searchParameters = {
                    collectionName: `${dbCollections.APPLICATIONS}`,
                    search: {
                        q:"*",
                        filter_by: `selectedStudent.id:=${self.docId}`,
                        page: self.currentPage,
                        per_page: self.perPage,
                    }
                }

                if (this.sortContect) {
                    searchParameters.search.sort_by = `${(this.sortContect.sortBy && this.sortContect.sortBy !== '') ? `${this.sortContect.sortBy}: ${this.sortContect.sortDesc == false ? 'asc' : 'desc'}` : ''}`;
                }
                getCollectionDataTypesense(searchParameters).then((result)=>{
                    if(result.hits.length) {
                        self.totalData = result.found
                        const arrayData = result.hits.map(x => {
                            const applicationObject = x.document;
                            let elements = {
                                shortlistPeding : 0,
                                shortlistNotEligible : 0,
                                shortlist_pay_pend : 0,
                                shortPaySuccessful : 0,
                                shortApplicationSub : 0,
                                shortlistinProgress : 0,
                                shortlistSuccessfull : 0,
                                shortlistreject : 0
                            };
                            let displayStatus = 'Pending'
                            let applicationStatusVariant = 'primary'
                            if(applicationObject.application_Status.toLowerCase()=='pending'){
                                displayStatus = 'Pending'
                                applicationStatusVariant = 'primary'
                            }
                            if(applicationObject.application_Status.toLowerCase()=='inprogress'){
                                displayStatus = 'In Progress'
                                applicationStatusVariant = 'warning'
                            }
                            if(applicationObject.application_Status.toLowerCase()=='paymentpending'){
                                displayStatus = 'Payment Pending'
                                applicationStatusVariant = 'secondary'
                            }
                            if(applicationObject.application_Status.toLowerCase()=='paymentsuccessful'){
                                displayStatus = 'Payment Successful'
                                applicationStatusVariant = 'info'
                            }
                            if(applicationObject.application_Status.toLowerCase()=='applicationsubmitted'){
                                displayStatus = 'Application Submitted'
                                applicationStatusVariant = 'dark'
                            }
                            if(applicationObject.application_Status.toLowerCase()=='admissionsuccessful'){
                                displayStatus = 'Admission Offered'
                                applicationStatusVariant = 'success'
                            }
                            if(applicationObject.application_Status.toLowerCase()=='admissionrejected'){
                                displayStatus = 'Rejection'
                                applicationStatusVariant = 'danger'
                            }            
                            if(applicationObject.application_Status.toLowerCase()=='noteligible'){
                                displayStatus = 'Not Eligible'
                                applicationStatusVariant = 'dark'
                            }
                            if(applicationObject.shortListedColleges !== undefined){

                                applicationObject.shortListedColleges.forEach(valuess=>{
                                    if(valuess){ 
                                        if(valuess.status == 'pending'){
                                        elements.shortlistPeding = Number(JSON.parse(elements.shortlistPeding) + 1);
                                        }
                                        if(valuess.status == 'inProgress'){
                                        elements.shortlistinProgress = Number(JSON.parse(elements.shortlistinProgress) + 1);
                                        }
                                        if(valuess.status == 'admissionSuccessful'){
                                        elements.shortlistSuccessfull = Number(JSON.parse(elements.shortlistSuccessfull) + 1);
                                        }
                                        if(valuess.status == 'admissionRejected'){
                                        elements.shortlistreject = Number(JSON.parse(elements.shortlistreject) + 1);
                                        } 
                                        if(valuess.status == 'paymentPending'){
                                            elements.shortlist_pay_pend = Number(JSON.parse(elements.shortlist_pay_pend) + 1);
                                        }
                                        if(valuess.status == 'paymentSuccessful'){
                                            elements.shortPaySuccessful = Number(JSON.parse(elements.shortPaySuccessful) + 1);
                                        }
                                        if(valuess.status == 'applicationSubmitted'){
                                            elements.shortApplicationSub = Number(JSON.parse(elements.shortApplicationSub) + 1);
                                        }
                                        if(valuess.status == 'notEligible'){
                                            elements.shortlistNotEligible = Number(JSON.parse(elements.shortlistNotEligible) + 1);
                                        }
                                    }
                                })
                            }


                            return {
                                ...applicationObject,
                                id: applicationObject.id,
                                ApplyDate: applicationObject.createdAt ? moment(new Date(applicationObject.createdAt * 1000)).format('DD MMM, yyyy') : '',
                                studentName: applicationObject.selectedStudent.name,
                                shorlisted: applicationObject?.shortListedColleges?.length || 0,
                                shortListStatus: elements,
                                displayStatus,
                                applicationStatusVariant
                            };
                        });

                        this.applicationData = arrayData;

                    } else {
                        this.applicationData = [];
                        self.totalData = 0;
                    }
                }).catch((error)=>{
                    this.applicationData = [];
                    self.totalData = 0;
                    console.error(error)
                })
            } catch (error) {
                this.applicationData = [];
                self.totalData = 0;
                console.error(error)
            }
        },
        getData(){
            var self = this;

            self.isSpinner = true

            db
            .collection(dbCollections.USERS)
            .doc(self.docId)
            .get()
            .then((doc)=>{
                self.userName=doc.data().name
                let elements = doc.data();
                if(elements.imageName == '' || elements.imageName == undefined){
                    elements.imageName = 'defaultUser.jpg'
                }
                self.userData=elements;
                db.collection(dbCollections.USERS).doc(doc.data().representative.id).get().then((datas)=>{
                    self.userData.representative.name = datas.data().name;
                    self.userData.representative.phoneNo = (datas.data().countryPhoneCode ? datas.data().countryPhoneCode.dialCode : '+91') + ' ' + (datas.data().countryPhoneCode ? formatPhoneNumber(datas.data().personalPhoneNo,datas.data().countryPhoneCode.mask) : formatPhoneNumber(datas.data().personalPhoneNo,'99999 99999'));
                })
                self.userData.visaStatus = doc.data().visaStatus || { status:"Pending", variant:"warning"};
                self.userData.visaDate = doc.data().visaDate || "";
                if(self.userData !== null){
                    self.$root.$emit('editUserData',doc.data().isActive||false)
                }
                self.isSpinner = false;
                // getQuery.getDataWithSpecificWhereConditionAndOrderBy(dbCollections.APPLICATIONS,res=>{ 
                // })
            })
            .catch(error=>{console.error(error);self.isSpinner = false;})
       },
        addZero(val)
        {
            if(val<10)
            {
                return `0`+val
            }
            else
            {
                return val
            }
        },
        getMaxDate()
        {
            return `${this.addZero(new Date().getFullYear())}-${this.addZero(new Date().getMonth()+1)}-${this.addZero(new Date().getDate())}`
        },
        changeStatus(status)
        {
            var self = this
            if(self.userData.status.status && status && self.userData.status.status.toLowerCase().replaceAll(" ", "_") == status.status.toLowerCase().replaceAll(" ", "_")) {
                return;
            }

            self.userData.status = status
            db
                .collection(dbCollections.USERS)
                .doc(self.docId)
                .update({
                    updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                    status:status
                })
                .then(()=>{
                    let notificationObj = {
                        title: `Changed Account Status`,
                        message: `Your Account status changed to "${status.status}"`,
                        type: 'user',
                        userId: self.docId,
                        currentUser:firebase.auth().currentUser.uid
                    }
                    this.$axios.post(dbCollections.APIURL+'api/v1/transectionNotification',notificationObj).then(()=>{
                    }).catch((error)=>{
                        console.error(error);
                    })
                    self.$root.$emit('showToastMessage','Status updated successfully.','success')
                })
                .catch(error=>{
                    self.$root.$emit('showToastMessage',error.message,'danger')
                })
        },
        addPayment(e)
        {
            var self = this
            self.$refs.paymentValidator.validate()
            .then((success)=>{
                if(success)
                {
                    self.userData.payments.push(self.addPaymentData)

                    db
                        .collection(dbCollections.USERS)
                        .doc(self.docId)
                        .update({
                            payments:self.userData.payments,
                            updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp()
                        })
                        .then(()=>{
                            self.$bvModal.hide('modal-add-payment')
                        })
                        .catch(error=>{
                            console.log(error)
                        })
                }
            })
        },
        cancelPayment(e)
        {
            this.addPaymentData={
                paymentReceivedDate:'',
                modeOfPayment: '',
                bankName: '',
                chequeNo:'',
                remark:'',
                rupees: ''
            }
        },
        userUpdateActive(updateObj,callBack){
            var self = this;
            if(updateObj !== undefined && updateObj !== null){
                updateObj.updatedAt=firebaseTimeStamp.firestore.FieldValue.serverTimestamp()
                updateQuery.updateRootCollectionDataByDocId(dbCollections.USERS,self.docId,updateObj,res=>{
                       if(updateObj.isActive == false){                       
                        callBack(true)
                        return;
                       }else{                       
                        callBack(true)
                        return;
                       }
                })
            }else{
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Something Went Wrong [please refresh the page]',
                            icon: 'CoffeeIcon',
                            variant: 'success',                               
                        },
                    })  
                    callBack(true)
                    return;
            }
        }
    },
    computed:{
        offeredApplications(){
            var self = this;

            let res = [];

            self.applicationData.forEach((data=>{
                if(data.shortListedColleges && data.shortListedColleges.length) {
                    data.shortListedColleges.filter((x) => {
                        if (x.status == "admissionSuccessful") {
                            let ind = res.findIndex((y)=> y.college_name === x.college_name && y.dispCourseName === x.dispCourseName )
                            if(ind === -1) {
                                res.push({...x,isBold:data.finalSelected ? (data.finalSelected.cllgName === x.college_name && data.finalSelected.courceName === x.dispCourseName) : false});
                            }
                            // res.push({...x,isBold:data.finalSelected ? (data.finalSelected.cllgName === x.college_name && data.finalSelected.courceName === x.dispCourseName) : false});
                            return true;
                        }
                    });
                }
            }))

            return res;
        },
        dataMeta(){
            const localItemsCount = this.docCategoryTable ? this.docCategoryTable.length : 0
            return {
                from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
                to: this.perPage * (this.currentPage - 1) + localItemsCount,
                of: this.applicationData,
            }
        }  
    },
    beforeDestroy()
    {
        this.$root.$off('blockUser');
        this.$root.$off('unBlockUser');
    }
}
</script>
<style scoped>
.bottom-border{
    border-bottom: 1px solid #cfcfcf;
}
.card-bottom-border{
    border-bottom: 1px solid #cfcfcf;
    border-radius: unset;
    margin-top: 15px;
    padding-bottom: 15px;
}
.card-title-border-bottom{
    border-top:1px solid #cfcfcf;
    padding-top:1.5rem;
}
.paddingBackGround{
    background-color:blueviolet;
    color: white;
}
.successBackGround{
    background-color:green;
    color: white;
}
.progressBackGround{
    background-color: yellow;
    color: black;
}
.rejectedBackGround{
    background-color:red;
    color: white;
}
.applicationNotEligibleStudent {
    border-color: #dfecec!important;
    background-color: #dfecec!important;
    color: #396060!important;
    padding: 8px 10px !important;
    border-radius: 10px !important;
}
</style>