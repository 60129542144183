import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

//firebase
import firebase from "@/utils/firebaseInit"
import firebaseTimeStamp from "firebase"
import {dbCollections} from "@/utils/firebaseCollection"
import { sort } from 'postcss-rtl/lib/affected-props'
const db = firebase.firestore()
const months=["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'no', sortable: false },
    { key: 'document_name', sortable: true },
    // { key: 'category', sortable: false },
    { key: 'manage_by_student', sortable: false },
    { key: 'upload_file', sortable: false },
    { key: 'status', sortable: false },
    { key: 'action', sortable: false },
    { key: 'Uploaded_Date', sortable: false },
    // { key: 'email', sortable: false },
    // { key: 'role', sortable: false },
    // {
    //   key: 'currentPlan',
    //   label: 'Plan',
    //   formatter: title,
    //   sortable: true,
    // },
    // { key: 'status', sortable: true },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('document_name')
  const isSortDirDesc = ref(null)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const representativeOptions = ref(null)

  const docId = ref(null)
  const docList = ref(null)

  //Select Filters
  const category = ref(null)
  const categoryOptions = ref(null)
  const uploadBy = ref(null)
  const status = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  
  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, status, uploadBy, category], () => {
    refetchData()
  })
  
  var usersList = []
  var repOptions=[{value:null,text:'All'}]
  var userDocsData = ''
  var catOptions = [{value:null,text:'All'}]
  const fetchUsers = (ctx, callback) => {
    var users = []
    console.log("get List")

    if(usersList.length==0)
    {
      // usersList=[]
      db
        .collection(dbCollections.USERS)
        .doc(docId.value)
        .onSnapshot(UD=>{
          var arr = []
          usersList=[]
          users=[]
          userDocsData = UD.data().docFiles
          var startIndex = (currentPage.value-1)*perPage.value+1
          var endIndex = startIndex+perPage.value-1
          var index = 0
          var dataIndex = 1

          const getNextDocuments = function(dt){
            if(index == UD.data().documentModule.length)
            {
              users=[]
              totalUsers.value = usersList.length
              representativeOptions.value=repOptions
              // console.log("rep",repOptions,usersList)

              UD.data().docFiles.forEach(docFile=>{
                usersList.forEach(ULData=>{
                  if(docFile.id == ULData.id)
                  {
                    ULData.fileType.forEach(doc1=>{
                      docFile.docs.forEach(doc2=>{
                        if(doc2.fileSide.toLowerCase() == doc1.text.toLowerCase())
                        {
                          // console.log("Found")
                          doc1.file=doc2
                        }
                        ULData.uploadDates = doc2.uploadDate ? doc2.uploadDate : ""
                      })
                    })
                    ULData.rejectReason=docFile.rejectReason||{reasons:[],rejectReasonRemark:""}
                    ULData.status=docFile.status
                  }
                })
              })

              usersList.sort((a, b) => (a.documentName.documentName > b.documentName.documentName ? 1 : -1))//Asc

              usersList.forEach(docs=>{
                if(dataIndex>=startIndex && dataIndex<=endIndex)
                {
                  users.push({
                    'id':docs.id,
                    'isDeleted':docs.isDeleted,
  
                    'document_name':docs.documentName,
                    'category':docs.categoryName,
                    'upload_file':docs.fileType,
                    'manage_by_student':docs.manageByStudent,
                    'status':docs.status,
                    'reject_reason':docs.rejectReason,
                    'commonIn':docs.commonIn,
                    'uploaded_date':docs.uploadDates,
                  })
                  dataIndex++;
                }
              })

              //GetList
              docList.value=usersList

              categoryOptions.value=catOptions

              // console.log("Final",users)
              callback(users)
              refetchData()
              return;
            }

            db
              .collection(dbCollections.DOCUMENTCATEGORY)
              .doc(dt.id)
              .get()
              .then(doc=>{
                var docIndex = 0
                if(!doc.data().isDeleted)
                {
                  catOptions.push(doc.data().categoryName);

                  doc.data().documents.forEach(documentId=>{
                    db
                      .collection(dbCollections.DOCUMENTS)
                      .doc(documentId)
                      .get()
                      .then(docData=>{
                        var docExists = false
  
                        usersList.forEach((ULD,ind)=>{
                          if(ULD.id==documentId)
                          {
                            docExists = true
                            var commonExists = false
                            ULD.commonIn.forEach(commons=>{
                              if(commons==doc.data().categoryName)
                              {
                                commonExists = true
                              }
                            })
                            if(!commonExists)
                            {
                              usersList[ind].commonIn.push(doc.data().categoryName);
                            }
                          }
                        })
  
                        if(!docExists)
                        {
                          usersList.push({
                            'id':docData.data().id,
                            'isDeleted':doc.data().isDeleted||false,
                            'documentName':{id:docData.data().id,documentName:docData.data().documentName},
                            'categoryName':doc.data().categoryName,
                            'fileType':docData.data().fileType.toLowerCase()=='single'?[{text:'single',file:'',errorMsg:'',isSpinner:false}]:[{text:'Front Side',file:'',errorMsg:'',isSpinner:false},{text:'Back Side',file:'',errorMsg:'',isSpinner:false}],
                            'manageByStudent':doc.data().manageByStudent,
                            'status':{variant:'primary',status:'Doc. pending'},
                            'commonIn':[doc.data().categoryName],
                            'rejectReason':{reasons:[],rejectReasonRemark:""},
                          })
                        }
                        docIndex++
                        if(docIndex==doc.data().documents.length)
                        {
                          index++;
                          getNextDocuments(UD.data().documentModule[index])
                        }
                      })
                      .catch(error =>{
                        console.log("Error",error)
                      })
                  })
                }
                else
                {
                  index++;
                  getNextDocuments(UD.data().documentModule[index])
                }
              })
              .catch(error =>{
                console.log("Error",error)
              })
          }
          getNextDocuments(UD.data().documentModule[index])

          UD.data().documentModule.forEach(dt=>{
            
          })
        })
        // .catch(error =>{
        //   console.log("Error",error)
        // })
    }
    else
    {
      console.log("Already has list data")
      var startIndex = (currentPage.value-1)*perPage.value+1
      var endIndex = startIndex+perPage.value-1
      var index = 1
      console.log(startIndex,endIndex)

      if(isSortDirDesc.value==false)
      {
        usersList.sort((a, b) => (a.documentName.documentName > b.documentName.documentName ? 1 : -1))//Asc
      }
      else if(isSortDirDesc.value==true)
      {
        usersList.sort((a, b) => (a.documentName.documentName > b.documentName.documentName ? -1 : 1))//Desc
      }
      usersList.forEach(data=>{
        if(searchQuery.value=='' && status.value==null && category.value==null && uploadBy.value==null)
        {
          if(index>=startIndex && index<=endIndex && !data.isDeleted)
          {
            users.push({
              'id':data.id,
              'isDeleted':data.isDeleted||false,

              'document_name':data.documentName,
              'category':data.categoryName,
              'upload_file':data.fileType,
              'manage_by_student':data.manageByStudent,
              'status':data.status,
              'commonIn':data.commonIn,
              'reject_reason':data.rejectReason,
              'uploaded_date':data.uploadDates ? data.uploadDates : "",
            })
          }
            // index++
          if(index==usersList.length)
          {
            // if(currentPage.value > 1 && users.length==0)
            // {
            //   currentPage.value -= currentPage.value
            // }
            totalUsers.value = usersList.length
            callback(users)
          }
          index++;
        }
        else if(!data.isDeleted)
        {
          // if(searchQuery.value!='')
          // {
            console.log("else if")
            users.push({
              'id':data.id,
              'isDeleted':data.isDeleted||false,

              'document_name':data.documentName,
              'category':data.categoryName,
              'upload_file':data.fileType,
              'manage_by_student':data.manageByStudent,
              'status':data.status,
              'commonIn':data.commonIn,
              'reject_reason':data.rejectReason,
              'uploaded_date':data.uploadDates ? data.uploadDates : "",
            })
            if(index==usersList.length)
            {
              var finalArr = users;
              var tmp = [];
              var dataIndex = 1

              //Search Filter
              if(searchQuery.value!='')
              {
                finalArr.forEach(UD=>{
                  if(
                    searchQuery.value && UD.category.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
                    searchQuery.value && UD.document_name.documentName.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
                    searchQuery.value && UD.status.status.toLowerCase().includes(searchQuery.value.toLowerCase())
                  )
                  {
                    tmp.push(UD)
                  }
                })
                finalArr=tmp
                tmp=[]
              }

              //Status Filter
              if(status.value!=null)
              {
                finalArr.forEach(UD=>{
                  if(status.value.toLowerCase() == UD.status.status.toLowerCase())
                  {
                    tmp.push(UD)
                  }
                })
                finalArr=tmp
                tmp=[]
              }
              //Category Filter
              if(category.value!=null)
              {
                finalArr.forEach(UD=>{
                  if(category.value.toLowerCase() == UD.category.toLowerCase())
                  {
                    tmp.push(UD)
                  }
                })
                finalArr=tmp
                tmp=[]
              }
              //Manage By Filter
              if(uploadBy.value!=null)
              {
                finalArr.forEach(UD=>{
                  if(uploadBy.value.toLowerCase() == UD.manage_by_student.toLowerCase())
                  {
                    tmp.push(UD)
                  }
                })
                finalArr=tmp
                tmp=[]
              }

              //Final Table Data
              finalArr.forEach(FD=>{
                if(dataIndex>=startIndex && dataIndex<=endIndex && !FD.isDeleted)
                {
                  tmp.push(FD)
                }
                dataIndex++
              })

              // if(currentPage.value > 1 && users.length==0)
              // {
              //   currentPage.value -= currentPage.value
              // }
              totalUsers.value = finalArr.length
              callback(tmp)
            }
            index++;
          // }
        }
      })
    }

    // for (let index = 0; index < 10; index++) {
    //   users.push({
    //     'No':index,
    //     'Date of Inquiry':`Date ${index}`,
    //     'Student Name':`Name ${index}`,
    //     'Phone no.':9462489464654,
    //     'College Name.':`Test`,
    //     'Branch.':`abcd`,
    //     'CGPA(%).':(index+1*(Math.random()*100)%100).toFixed(2),
    //     'actions':`<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-icon" > Create Account </b-button> <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-icon" > Create New Inquiry </b-button>`
    //   })
    // }
    // callback(users)
  }


  const appendUploadedFile = function(userDoc,cb){
    // console.log('userDoc',userDoc)
    // usersList.forEach((ULData,index)=>{
    //   if(userDoc.id == ULData.id)
    //   {
    //     ULData.fileType.forEach(doc1=>{
    //       userDoc.docs.forEach(doc2=>{
    //         if(doc2.fileSide == doc1.text)
    //         {
    //           doc1.file=doc2
    //           // console.log("Found",doc1.file,usersList)
    //         }
    //       })
    //     })
    //     ULData.status=userDoc.status
    //   }
    // })
    // refetchData()
    // cb(true)
  }

  const approveDocument = function(item,cb)
  {
    // console.log("Item",item)

    // usersList.forEach((data,index)=>{
    //   if(data.id == item.id)
    //   {
    //     data.status={status:'Doc. Approved',variant:'success'}
    //   }
    // })
    // refetchData()
    // cb(true)
  }

  const rejectDocument = function(item,reason,reasonText,cb)
  {
    // console.log("Item",item)

    // usersList.forEach((data,index)=>{
    //   if(data.id == item.id)
    //   {
    //     data.rejectReason=item.reject_reason
    //     data.status={status:'Doc. Rejected',variant:'danger'}
    //   }
    // })
    // refetchData()
    // cb(true)
  }

  const removeDocument = function(userDoc,ind,cb)
  {
    // console.log("Item",userDoc)
    // usersList.forEach((ULData,index)=>{
    //   if(userDoc.id == ULData.id)
    //   {
    //     ULData.fileType.forEach((doc1,index2)=>{
    //       userDoc.docs.forEach(doc2=>{
    //         if(doc2.fileSide == doc1.text && index2 == ind)
    //         {
    //           doc1.file=''
    //           // console.log("Found",doc1.file,usersList)
    //         }
    //       })
    //     })
    //     ULData.status={status:'Doc. Pending',variant: 'primary'}
    //   }
    // })
    // cb(true)
    // refetchData()
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    representativeOptions,
    // closeInquiry,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,

    category,
    categoryOptions,
    uploadBy,
    status,

    //My Values
    docId,
    docList,
    approveDocument,
    rejectDocument,
    removeDocument,
    appendUploadedFile,
  }
}
