<template>
    <div>
        <div>
            <div style="margin-bottom:50px;"> 
                <div class="row">
                    <div class="col-md-6">
                        <div class="upload_wrapper">
                            <div class="upload_save">
                                <b-form-group label="Rough SOP">
                                    <!-- <b-form-file
                                        class="pointerClassCss"
                                        placeholder="Upload File"
                                        ref="uploadPdfFile1"
                                        @change.prevent="listFileUploads"
                                        id="uploadFileArray"
                                        required
                                    /> -->
                                </b-form-group>
                            </div>
                            <!-- <div class="text-right upload_save_called">
                                <button class="btn btn-primary" @click="fileUploadFunctionHandler('roughSop')" :disabled="isSpinner">Save</button>
                            </div> -->
                        </div>
                        <div v-if="attachemntArray && attachemntArray.length > 0">
                            <div v-for="(files,index) in attachemntArray" :key="index" class="d-flex align-items-center">
                                <span class="fileUploadColor deleteBtn">
                                    {{files.file}}
                                </span>
                                <span class="delete-pdf" v-if="!isSpinner">
                                    <!-- <img style="cursor:pointer;" @click="downloadDocument(files)" src="../../../assets/images/erflog/view.png" /> -->
                                    <img style="cursor:pointer;" @click="downloadDocument(files,'rough')" src="../../../assets/images/icons/material-symbols_download.png" />
                                </span>
                                <span v-if="!isSpinner">
                                    <img style="cursor:pointer;" @click="openModel('rough',files)" src="../../../assets/images/erflog/Delete.png" />
                                </span>
                                <span class="delete-pdf">
                                    <b-spinner variant="primary" v-if="isSpinner" large class="spinner-border-data"/>
                                </span>
                            </div>
                        </div>
                        <div v-else>
                            No Record Found
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="upload_wrapper">
                            <div class="upload_save">
                                <b-form-group label="Upload SOP Here">
                                    <b-form-file
                                        :disabled="isSpinner2"
                                        class="pointerClassCss"
                                        :class="{'removeArrow': isSpinner2}"
                                        placeholder="Upload File"
                                        ref="uploadPdfFile2"
                                        @change.prevent="listFileUploadsSop"
                                        id="uploadFileArray1"
                                        required
                                    />
                                </b-form-group>
                            </div>
                            <div class="text-right upload_save_called">
                                <button class="btn btn-primary" @click="fileUploadFunctionHandler('sop')" :disabled="isSpinner2">Save</button>
                            </div>
                        </div>
                        <div v-if="attachemntArraySOP && attachemntArraySOP.length > 0">
                            <div v-for="(files,index) in attachemntArraySOP" :key="index" class="d-flex align-items-center">
                                <span class="fileUploadColor deleteBtn">
                                    {{files.file}}
                                </span>
                                <span class="delete-pdf" v-if="!isSpinner2">
                                    <!-- <img style="cursor:pointer;" @click="downloadDocument(files)" src="../../../assets/images/erflog/view.png" /> -->
                                    <img style="cursor:pointer;" @click="downloadDocument(files,'sop')" src="../../../assets/images/icons/material-symbols_download.png" />
                                </span>
                                <span v-if="!isSpinner2">
                                    <img style="cursor:pointer;" @click="openModel('sop',files)" src="../../../assets/images/erflog/Delete.png" />
                                </span>
                                <span class="delete-pdf">
                                    <b-spinner variant="primary" v-if="isSpinner2" large class="spinner-border-data"/>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <b-modal
                id="modal-removeDoc"
                ok-title="Yes"
                cancel-title="No"
                centered
                @ok="removeDoc"
                @cancel="cancelModelHandler"
                hide-header
            >
            <h3>Are you sure you want to delete file?</h3>
            </b-modal>
        </div>
    </div>
</template>
<script>
import * as uploadImageFile from '@/utils/FirebaseQueries/storageQuery/storageQueries';
import * as updateQuery from '@/utils/FirebaseQueries/updateQueries/updateQueries.js';
import { dbCollections } from '@/utils/firebaseCollection.js';
import firebase from "firebase";
import firebaseDatabase from "@/utils/firebaseInit.js";
const db = firebaseDatabase.firestore();
import axios from 'axios';
import {
  BFormGroup,BFormFile,BSpinner,BModal
} from 'bootstrap-vue';
export default {
  name: "sop-upload",
    data() {
        return {
            attachemntArray:[],
            attachemntArraySOP:[],
            commonArrayFOr:[],
            commonArrayFOrSOP:[],
            fileType: '', 
            fileNameRoughSop: '', 
            fromWhere:'',
            dataFromDB:null,
            isSpinner:false,
            isSpinner2:false,
            subscribeSnap: null
        };
    },
    components: {
        BFormGroup,
        BFormFile,
        BSpinner,
        BModal,
    },
    beforeDestroy(){
        if(this.subscribeSnap !== null){
            this.subscribeSnap();
        }
    },
    created(){
        this.subscribeSnap = db.collection(dbCollections.USERS).where("id",'==',this.$route.params.id)
        .onSnapshot((querySnapshot) => {
            if(querySnapshot.empty) {
                this.attachemntArraySOP = [];
                this.attachemntArray = [];    
                return;
            }
            querySnapshot.forEach((doc) => {
                if(doc.data().hasOwnProperty('SopUploadFile') && Object.keys(doc.data().SopUploadFile).length > 0){
                    this.attachemntArraySOP = [];
                    this.attachemntArraySOP.push({
                        fileName: doc.data().SopUploadFile.imageName,
                        file: this.documentName(doc.data().SopUploadFile.imageName),
                        name: doc.data().SopUploadFile.imageUrl,
                        from:'database'
                    });
                } else {
                    this.attachemntArraySOP = [];
                }
                if(doc.data().hasOwnProperty('RoughSopUploadFile') && Object.keys(doc.data().RoughSopUploadFile).length > 0){
                    this.attachemntArray = [];
                    this.attachemntArray.push({
                        fileName: doc.data().RoughSopUploadFile.imageName,
                        file: this.documentName(doc.data().RoughSopUploadFile.imageName),
                        name: doc.data().RoughSopUploadFile.imageUrl,
                        from:'database'
                    });
                } else {
                    this.attachemntArray = [];
                }
            });
        });
    },
    methods:{
        //SOP UPLOAD
        listFileUploadsSop(e) {
            var self = this;
            try {
                let files = e?.srcElement?.files ? e.srcElement.files : e;
                let validFileSize = false;
                let mb = 10;
                if(files && files.length !== 0) {
                    self.filesArraySOP = [];
                    self.filesArraySOP = files;
                    self.filesArraySOP.forEach((element)=>{
                        if(element.size >= mb * 1000000){
                            validFileSize = true;
                            return;
                        }
                    });
                    if(!validFileSize) {
                        if(self.attachemntArraySOP && self.attachemntArraySOP.length >= 1){
                            document.getElementById("uploadFileArray1").value=null;
                            this.$root.$emit('showToastMessage','First remove uploaded file.','danger')
                            return;
                        }
                        let attachmentsURLsArray = [];
                        let count = 0;
                        try {
                            const uploadFun = async (row) => {
                                if(count >= self.filesArraySOP.length) {
                                    if(self.attachemntArraySOP == undefined || self.attachemntArraySOP.length == 0) {
                                        const obj = {
                                            'attachments': attachmentsURLsArray
                                        }
                                        if(Object.keys(obj).length > 0 && obj !== null){
                                            self.attachemntArraySOP = attachmentsURLsArray
                                            self.$forceUpdate();
                                        }
                                    } else {
                                        const fileArrays = self.attachemntArraySOP;
                                        attachmentsURLsArray.forEach(element => {
                                            fileArrays.push(element);
                                        });
                                        const obj = {
                                            'attachments': fileArrays
                                        }
                                        if(Object.keys(obj).length > 0 && obj !== null){
                                            self.attachemntArraySOP = fileArrays
                                            self.$forceUpdate();
                                        }
                                    }
                                    document.getElementById("uploadFileArray1").value=null;
                                    return;
                                }
                                self.commonArrayFOrSOP.push(row);
                                var fileObject = row;
                                var url = fileObject.name;
                                    const imgObj = {
                                        'file':url,
                                        'fileName':url,
                                        'name':fileObject.lastModified + '_' +fileObject.name,
                                        'from':'local',
                                    }
                                    attachmentsURLsArray.push(imgObj);
                                count += 1;
                                uploadFun(self.filesArraySOP[count]);
                            }
                            uploadFun(self.filesArraySOP[count]);
                        } catch (error) {
                            document.getElementById("uploadFileArray1").value=null;
                            console.error('ERROR',error)
                        }
                    } else {
                        document.getElementById("uploadFileArray1").value=null;
                        this.$root.$emit('showToastMessage','File size should be less than 10 mb.','danger')
                    }
                }
            } catch (error) {
                console.error("ERRROR",error);
                this.$root.$emit('showToastMessage','Some thing went wrong.','danger');
                document.getElementById("uploadFileArray1").value=null;
            }
        },
        async fileUploadFunctionHandler(uploadtype){
            var self = this;
            let newArray = uploadtype == 'roughSop' ? this.commonArrayFOr : this.commonArrayFOrSOP;
            document.getElementById("uploadFileArray1").value=null;
            try {
                if(newArray.length == 0){
                    this.$root.$emit('showToastMessage','Please select file.','danger')
                    return;
                }
                // this.isSpinner = uploadtype == 'roughSop' ? true : false;
                // this.isSpinner2 = uploadtype == 'sop' ? true : false;
                if(uploadtype == 'roughSop'){
                    this.isSpinner = true;
                } else {
                    this.isSpinner2 = true;
                }
                var url = newArray[0].name;
                var myRnId = parseInt(Date.now() * Math.random());
                var fileName = myRnId + "_" + url;
                uploadImageFile.uploadImageFileWithoutUniqueString(`${uploadtype == 'roughSop' ? 'StudentRoughSopFiles' : 'StudentSopFiles'}`,fileName,newArray[0],res=>{
                    let updateObject = {
                        updatedAt : firebase.firestore.FieldValue.serverTimestamp(),
                    };
                    let key = uploadtype == 'roughSop' ? 'RoughSopUploadFile' : 'SopUploadFile';
                    updateObject[key] = res;
                    updateQuery.updateRootCollectionDataByDocId(dbCollections.USERS,this.$route.params.id,updateObject,()=>{
                        if(uploadtype == 'roughSop'){
                            this.attachemntArray = [{...this.attachemntArray[0],from:'database',name:res.imageUrl,fileName:res.imageName}]
                            this.commonArrayFOr = []
                            self.isSpinner = false;
                        } else {
                            this.attachemntArraySOP = [{...this.attachemntArraySOP[0],from:'database',name:res.imageUrl,fileName:res.imageName}]
                            this.commonArrayFOrSOP = []
                            self.isSpinner2 = false;
                            let notificationObj = {
                                title: `Sop`,
                                message: `A new SOP document has been added.`,
                                type: 'sop',
                                userId: this.$route.params.id,
                                currentUser:firebase.auth().currentUser.uid
                            }
                            this.$axios.post(dbCollections.APIURL+'api/v1/transectionNotification',notificationObj).then(()=>{
                            }).catch((error)=>{
                                console.error(error);
                            })
                        }
                        this.$root.$emit('showToastMessage','File uploaded successfully.','success')
                    })
                })
            } catch (error) {
                console.error(error)
                if(uploadtype == 'roughSop'){
                    this.isSpinner = false;
                } else {
                    this.isSpinner2 = false;
                }
            }
        },
        cancelModelHandler(){
            this.fileNameRoughSop = '';
            this.fileType = '';
            this.fromWhere = '';
        },
        openModel(docType,docobj){
            this.fileType = docType;
            this.fileNameRoughSop = docobj.fileName;
            this.fromWhere = docobj.from;
            this.$bvModal.show(`modal-removeDoc`);
        },
        removeDoc(){
            // this.isSpinner = this.fileType == 'rough' ? true : false;
            // this.isSpinner2 = this.fileType == 'rough' ? false : true;
            if(this.fileType == 'rough') {
                this.isSpinner = true;
            } else {
                this.isSpinner2 = true;
            }
            if(this.fromWhere == 'database'){
                try {
                    uploadImageFile.removeImage(`${(this.fileType == 'rough') ? 'StudentRoughSopFiles' : 'StudentSopFiles'}`,this.fileNameRoughSop,callBack=>{
                        let updateObject = {
                            updatedAt : firebase.firestore.FieldValue.serverTimestamp(),
                        };
                        let key = (this.fileType == 'rough') ? 'RoughSopUploadFile' : 'SopUploadFile';
                        updateObject[key] = {};
                        key = '';
                        updateQuery.updateRootCollectionDataByDocId(dbCollections.USERS,this.$route.params.id,updateObject,()=>{
                            if(this.fileType == 'rough'){
                                this.attachemntArray.splice(0,1);
                                this.commonArrayFOr.splice(0,1);
                                this.isSpinner = false;
                            } else {
                                this.attachemntArraySOP.splice(0,1);
                                this.commonArrayFOrSOP.splice(0,1);
                                this.isSpinner2 = false;
                            }
                            this.fileNameRoughSop = '';
                            this.fileType = '';
                            this.$root.$emit('showToastMessage','File deleted successfully.','success')
                        });
                    })
                } catch (error) {
                    this.isSpinner = false;
                    this.isSpinner2 = false;
                    console.error(error)
                }
            } else {
                if(this.fileType == 'rough'){
                    this.attachemntArray.splice(0,1);
                    this.commonArrayFOr.splice(0,1);
                    this.isSpinner = false;
                } else {
                    this.attachemntArraySOP.splice(0,1);
                    this.commonArrayFOrSOP.splice(0,1);
                    this.isSpinner2 = false;
                }
                this.fileNameRoughSop = '';
                this.fileType = '';
                this.fromWhere = '';
                this.$root.$emit('showToastMessage','File deleted successfully.','success')
            }
        },
        downloadDocument(docFile,type){
            var self = this;
            let doc = docFile.name;
            if(type == 'rough'){
                this.isSpinner = true;
            } else {
                this.isSpinner2 = true;
            }
            if(doc.includes('firebase')){
                var httpsReference = firebase.storage().refFromURL(doc);
                let arr = httpsReference.name.split('_');
                arr.splice(0,1);
                let fileName = arr.join("_");
                axios({
                    url: doc,
                    method: 'GET',
                    responseType: 'blob',
                }).then((response) => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');
        
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', fileName);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    this.$root.$emit('showToastMessage','File downloaded successfully.','success');
                    if(type == 'rough'){
                        this.isSpinner = false;
                    } else {
                        this.isSpinner2 = false;
                    }
                })
                .catch(error=>{
                    console.error(error);
                    if(type == 'rough'){
                        this.isSpinner = false;
                    } else {
                        this.isSpinner2 = false;
                    }
                    self.$root.$emit('showToastMessage',error.message,'danger')
                })
            }
            else{
                var fileURL = window.URL.createObjectURL(new Blob([this.commonArrayFOrSOP[0]]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', docFile.fileName);
                document.body.appendChild(fileLink);
                fileLink.click();
                this.$root.$emit('showToastMessage','File downloaded successfully.','success');
                if(type == 'rough'){
                    this.isSpinner = false;
                } else {
                    this.isSpinner2 = false;
                }
            }
        },
        documentName(doc) {
            let name = doc;
            let arr = name.split("_");
            arr.splice(0, 1);
            return arr.join("");
        },
    },
}
</script>
<style scoped>
span.deleteBtn {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #0d4ea0;
    word-break: break-all;
}
span.delete-pdf{
    padding: 0px 8px;
}
.pointerClassCss{
    cursor: pointer !important;
}
.upload_wrapper{
    display: flex;
    align-items: baseline;
    width: 100%;
    justify-content: space-between;
}
.upload_save {
    width: 85%;
}
.upload_save_called{
    width: 15%;
    position: relative;
    top: 3px;
}
.spinner-border-data {
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    vertical-align: text-bottom;
}
</style>
